import { back4app } from './apis';

export async function getActivities() {
  return await back4app.get('/pets/activities');
}

export async function getBehaviors() {
  return await back4app.get('/pets/behaviors');
}

export async function getBreeds() {
  return await back4app.get('/pets/breeds');
}

export async function getPetProfile(petId) {
  return await back4app.get(`/pet_profile/${petId}`);
}

export function deleteAllergiesConditions(petId, data) {
  return back4app.patch(`/pet_profile/${petId}`, data);
}

export function getPetFoods(query) {
  return back4app.get(`/petfoods/${query}`);
}

export function addPetFood(petId, data) {
  return back4app.post(`/pet_profile/${petId}/foods`, data);
}

export function deletePetFood(petId, foodId) {
  return back4app.delete(`/pet_profile/${petId}/foods/${foodId}`);
}

export function getPetVaccinations(petId) {
  return back4app.get(`/vaccines/${petId}`);
}

export function petPatch(petId, data) {
  return back4app.patch(`/pet_profile/${petId}`, data);
}

export function deletePetVaccination(petId, vaccineType) {
  return back4app.delete(`/vaccines/delete/${petId}/${vaccineType}`);
}

export function deleteAllPetVaccination(petId) {
  return back4app.delete(`/vaccines/delete_all/${petId}`);
}

export async function getMedicalRecordRequets(petId) {
  return await back4app.get(`/medical_records/records_requests/${petId}`);
}

export function updateMedicalRecordRequestStatus(id, status) {
  return back4app.patch(`/medical_records/records_request/${id}`, { status });
}

export function setAllergiesAndConditions(petId, data) {
  return back4app.patch(`/pet_profile/${petId}`, data);
}

export function deleteAllergiesAndConditions(petId, data) {
  return back4app.patch(`/pet_profile/${petId}`, data);
}

export function getActivitiesAndBehaviors(petId, attribute) {
  return back4app.get(`//pets/psychographic_attitudinal_behavioral/${petId}/${attribute}`);
}

export function setActivitiesAndBehaviors(petId, attribute, data) {
  return back4app.post(`/pets/psychographic_attitudinal_behavioral/${petId}/${attribute}`, data);
}

export function updatePet(petId, weight, microchipId, microchipManufacturer, vaccineRecency) {
  return back4app.patch(`/pet_profile/${petId}`, {
    weight,
    microchipId,
    microchipManufacturer,
    vaccineRecency
  });
}

export function editPetVaccination(petId, oldVaccineType, vaccineName, administeredDate, renewalDate, type) {
  return back4app.patch(`/pets/${petId}/vaccines/${oldVaccineType}`, {
    vaccineName,
    administeredDate,
    renewalDate,
    type
  });
}

export function uploadDocument(petId, formData) {
  return back4app.post(`/documents/${petId}/vet_records/`, formData);
}

export function updateDocumentVetName(documentUrl, value) {
  const data = {
    "vet-name": value,
  };
  return back4app.put(`/documents/attributes?documentUrl=${encodeURIComponent(documentUrl)}`, data);
}
